body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Fonts */

@font-face {
  font-family: Gilroy Light;
  src: url("./assets/font/Radomir\ Tinkov\ -\ Gilroy-Light.otf");
}

@font-face {
  font-family: Gilroy B;
  src: url("./assets/font/Radomir\ Tinkov\ -\ Gilroy-Bold.otf");
}

@font-face {
  font-family: Source Sans Pro, sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300&display=swap");
}

body,
h1 {
  font-family: Gilroy Light;
}

body,
html {
  height: 100%;
  margin: 0;
  background-color: #eeeff6;
  letter-spacing: 0.05em;
}

#root {
  height: 100%;
  margin: 0;
}

.bgimg {
  min-height: 100%;
  max-height: 100%;
  background-position: center;
  background-size: cover;
}

.hidden-background {
  visibility: hidden;
  min-height: 100%;
  width: 100%;
}

#mc_embed_signup {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 19px;
  font-weight: 300;
  letter-spacing: 0.03em;
}

.MuiTypography-body1 {
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  letter-spacing: 0.05em !important;
}

.MuiInputBase-root {
  font-family: "Source Sans Pro", sans-serif !important;
  font-size: 19px !important;
  font-weight: 300 !important;
  letter-spacing: 0.03em !important;
}

.MuiInputLabel-root {
  font-weight: 300 !important;
}

.MuiFormHelperText-root {
  font-weight: 300 !important;
}

@keyframes opacityAndSlideInFromBottom {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.bgimg ::selection {
  background-color: #00315f;
}

.text-white {
  color: #eeeff6;
}

.text-container {
  resize: none;
}

.extra-field {
  position: absolute;
  left: -5000px;
}

.input-group {
  vertical-align: top;
}

.animate-opacity {
  animation: 1s ease-out 0s 1 opacityAndSlideInFromBottom;
}

.display-container {
  position: relative;
}

.padding-large {
  padding: 12px 24px;
}

.large-text {
  font-size: 24px;
}

.xlarge-text {
  font-size: 50px;
  font-weight: 100;
  letter-spacing: 0.05em;
}

.display-top-left {
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 10px;
  padding-left: 44px;
}

.display-middle-left {
  left: 100px;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

.display-middle {
  display: inline-flex;
  align-items: center;
}

.display-x {
  position: absolute;
  bottom: -327px;
  left: 108px;
}

.display-x img {
  width: 463px;
  opacity: 0.65;
}

#iconButton {
  margin-right: 44px;
}

#menuLogo {
  margin-left: 44px;
  padding-top: 20px;
}

[class*="wide-"] {
  display: inline-block;
  padding: 0px 27px;
}

.row-1 {
  padding-left: 0px;
}

.row-2 {
  padding-left: 202px;
}

.row-3 {
  padding-left: 404px;
}

.row-4 {
  padding-left: 606px;
}

.row-5 {
  padding-left: 808px;
}

.row-6 {
  padding-left: 1010px;
}

.m-wide-1 {
  min-width: 202px;
}

.wide-1 {
  width: 202px;
}

.wide-2 {
  width: 404px;
}

.wide-3 {
  width: 606px;
}

.wide-4 {
  width: 808px;
}

.wide-5 {
  width: 1010px;
}

.wide-6 {
  width: 1212px;
}

.maxed {
  margin: auto;
  max-width: 1266px;
}

.maxed-flex {
  margin: auto;
  max-width: 1266px;
  display: flex;
}

.form-input {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  font-size: 19px;
  color: #eeeff6;
  margin: 12px;
  padding: 1.5rem 2rem;
  background: none;
  border: 1px #eeeff6 solid;
  box-sizing: border-box;
  width: 100%;
}

.mc-field-special {
  position: absolute !important;
  left: -5000px;
}

.form-input::placeholder {
  color: #eeeff6;
  opacity: 0.75;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #ffb1ab !important;
}

.MuiFormLabel-root.Mui-error {
  color: #ffb1ab !important;
}

.MuiFormLabel-asterisk.Mui-error {
  color: #ffb1ab !important;
}

.MuiFormHelperText-root.Mui-error {
  color: #ffb1ab !important;
}

.mc-field-group {
  box-sizing: border-box !important;
  padding: 10px !important;
}

.mc-field-group-left {
  margin-right: 17px !important;
  padding-bottom: 48px !important;
}

.mc-field-group-right {
  margin-left: 17px !important;
  padding-bottom: 48px !important;
}

.mc-field-group > .MuiInputLabel-outlined {
  transform: translate(26px, 32px) scale(1);
  color: #eeeff6;
  font-family: inherit;
}

.mc-field-group > .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(30px, 4px) scale(0.75);
}

.mc-field-group-right > .MuiInputLabel-outlined {
  transform: translate(36px, 32px) scale(1);
  color: #eeeff6;
  font-family: inherit;
}

.mc-field-group-right > .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(38px, 4px) scale(0.75);
}

.mc-field-group > .MuiFormLabel-root.Mui-focused {
  color: #eeeff6;
  font-weight: bold;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #eeeff6;
  background: #267abf;
  -webkit-box-shadow: 0 0 0px 1000px #2885d1 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.MuiFormControlLabel-root {
  padding-left: 22px;
  padding-top: 1px;
}

.MuiInputBase-input.MuiOutlinedInput-input {
  color: #eeeff6;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 19px;
  font-weight: 300;
  letter-spacing: 0.03em;
}

.checkbox-container {
  display: block;
  position: relative;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  font-size: 19px;
  padding: 12px 47px;
  cursor: pointer;
  height: 48px;
}

.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 12px;
  left: 12px;
  height: 25px;
  width: 25px;
  background: none;
  border: 1px #eeeff6 solid;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  background-color: #ffffff40;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  background-image: url("./assets/images/x.png");
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.source {
  background-color: #4d8fcc;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  font-size: 19px;
  color: #eeeff6;
  text-align: center;
  letter-spacing: 0.03em;
}

.source ::selection {
  background: #267abf;
  /* WebKit/Blink Browsers */
}

.form {
  background-color: #267abf;
  padding-top: 108px;
  padding-bottom: 108px;
  font-size: 19px;
}

.form ::selection {
  background-color: #00315f;
}

.form-desc {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 300;
  letter-spacing: 0.01em;
  font-size: 32px;
}

.picto {
  width: 22px;
  padding-right: 8px;
  vertical-align: middle;
}

.main-text {
  color: #4d8fcc;
  padding-bottom: 24px;
  padding-left: 8px;
}

.sticky {
  position: fixed;
  top: 0 !important;
  width: 100%;
}

.header {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  width: 100%;
  background: #eeeff6;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 44px;
  z-index: 10;
}

.header-enter {
  opacity: 0.01;
}

.header-enter.header-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}

.header-leave {
  opacity: 1;
}

.header-leave.header-leave-active {
  opacity: 0.01;
  transition: opacity 200ms ease-in;
}

.header-hidden {
  top: -100px;
}

.centered-description {
  padding-bottom: 56px;
  text-align: center;
  font-size: 22px;
}

.info {
  text-align: left;
  padding-top: 12px;
  padding-bottom: 12px;
  display: inline-block;
}

.copyright {
  text-align: right;
  padding-top: 12px;
  padding-bottom: 12px;
  display: inline-block;
}

.send-container {
  height: 109px;
  padding-left: 12px;
}

button.mfn-link {
  position: relative;
  display: inline-block;
  margin: 15px 19px;
  text-align: center;
  font-size: 12px;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  text-decoration: none;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  font-family: Gilroy B;
  color: #4d8fcc;
  letter-spacing: 0.05em;
}

button:hover.mfn-link {
  text-decoration: none;
}

button.mfn-link-4 {
  padding: 12px 10px 10px;
  text-shadow: none;
  font-weight: 900;
}

button.mfn-link-4:before,
button.mfn-link-4:after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 2px;
  content: "";
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
  -webkit-transform: scale(0.85);
  -moz-transform: scale(0.85);
  transform: scale(0.85);
}

button.mfn-link-4:after {
  opacity: 0;
  -webkit-transition: top 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: top 0.3s, opacity 0.3s, -moz-transform 0.3s;
  transition: top 0.3s, opacity 0.3s, transform 0.3s;
}

button:hover.mfn-link-4:before,
button:hover.mfn-link-4:after,
button.hover.mfn-link-4:before,
button.hover.mfn-link-4:after {
  -webkit-transform: scale(0.99);
  -moz-transform: scale(0.99);
  transform: scale(0.99);
}

.mfn-link-cont {
  text-align: left;
}

button:hover.mfn-link-4:after,
button.hover.mfn-link-4:after {
  top: 0;
  opacity: 1;
}

button.mfn-link-4:before,
button.mfn-link-4:after {
  background: rgb(33, 45, 116);
}

#mc-embedded-subscribe {
  color: #eeeff6;
  margin-top: 134px;
}

#mc-embedded-subscribe:before,
#mc-embedded-subscribe:after {
  background: #eeeff6;
}

#mc-embedded-subscribe.Mui-disabled {
  background: none !important;
  color: rgba(0, 0, 0, 0.26);
}

#mc-embedded-subscribe.Mui-disabled:before,
#mc-embedded-subscribe.Mui-disabled:after {
  background: rgba(0, 0, 0, 0.26);
}

#form-direct-button {
  margin-left: 10px;
}

#form-direct-button:before,
#form-direct-button:after {
  background: #267abf;
}

.announcement-section {
  padding: 115px 0px;
}

.announcement-section ::selection {
  color: #fff;
  background-color: #267abf;
}

@media (max-width: 1324px) {
  .display-x {
    bottom: -231px;
  }

  .display-x img {
    width: 325px;
  }

  .row-2 {
    padding-left: 16.6%;
  }

  .row-3 {
    padding-left: 33.3%;
  }

  .row-4 {
    padding-left: 50%;
  }

  .row-5 {
    padding-left: 66.6%;
  }

  .row-6 {
    padding-left: 83.3%;
  }

  .wide-1 {
    width: 16.6%;
  }

  .wide-2 {
    width: 33.3%;
  }

  .wide-3 {
    width: 48%;
  }

  .wide-4 {
    width: 66.6%;
  }

  .wide-5 {
    width: 83.3%;
  }

  .wide-6 {
    width: 100%;
  }

  .MuiFormControlLabel-root {
    padding-left: 12px;
  }

  .mc-field-group-right {
    margin-left: 0px !important;
  }

  .mc-field-group-left {
    margin-right: 0px !important;
  }
}

@media (max-width: 1024px) {
  body {
    height: initial;
  }

  .bgimg {
    height: 350px;
  }

  .w3 {
    width: 100%;
  }

  .display-middle-left {
    display: none;
  }

  .display-x {
    top: 309px;
    left: 25px;
    height: 150px;
  }

  .display-x img {
    width: 150px;
  }

  .padded {
    padding-left: 18px;
    padding-right: 18px;
    max-width: 100%;
  }

  [class*="row-"] {
    padding-left: 0px;
  }

  [class*="wide-"] {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }

  .m-wide-1 {
    min-width: 150px;
  }

  .wide-1 {
    width: 45%;
  }

  .maxed-flex {
    margin: auto;
    max-width: 100%;
    display: inline;
  }

  .display-top-left {
    padding-left: 44px;
    padding-top: 10px;
  }

  .header {
    padding-left: 16px;
    padding-top: 12px;
  }
  .display-top-left {
    padding-left: 16px;
    padding-top: 12px;
  }

  #logo {
    width: 120px;
  }

  #iconButton {
    margin-right: 8px;
  }

  #menuLogo {
    margin-left: 16px;
    padding-top: 22px;
    width: 120px;
  }

  .xlarge-text {
    font-size: 2em;
  }

  .checkbox-container {
    width: 100%;
    box-sizing: border-box;
    height: 80px;
  }

  .form {
    padding: 8px;
    padding-bottom: 32px;
    font-size: 15px;
  }

  .main-text {
    padding-bottom: 0px;
    padding-top: 32px;
  }

  .form-input {
    margin-left: 0px;
  }

  .form-info {
    padding: 10px;
  }

  .checkmark {
    left: 0px;
  }

  .centered-description {
    padding-bottom: 8px;
    text-align: left;
    font-size: 22px;
  }

  .checkbox-container {
    padding-left: 35px;
  }

  .send-container {
    padding-top: 12px;
    height: 48px;
    padding-left: 0px;
  }

  .source {
    font-size: 15px;
  }

  textarea {
    height: 100px;
  }

  .info {
    box-sizing: border-box;
    width: 100%;
    padding-left: 16px;
  }

  .copyright {
    box-sizing: border-box;
    width: 100%;
    padding-left: 16px;
    text-align: left;
    background-color: #4889c5;
  }

  #mc-embedded-subscribe {
    margin-top: 32px;
  }

  #mc-embedded-subscribe-error {
    margin-top: 0px;
  }

  .announcement-section {
    padding-top: 100px;
    padding-bottom: 30px;
    min-height: 256px;
  }

  #mc_embed_signup_scroll {
    text-align: center;
  }

  .input-group {
    text-align: left;
  }

  .form-desc {
    font-size: 22px;
  }
}

.menu-enter {
  height: 0px;
}

.menu-enter.menu-enter-active {
  padding-top: 30;
  height: 100vh;
  transition: height 300ms ease-out;
}

.menu-leave {
  padding-top: 30;
  height: 100vh;
}

.menu-leave.menu-leave-active {
  height: 0px;
  transition: height 200ms ease-out;
}
